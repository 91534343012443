export const CONFIG = {
  API_URL: "https://v2-api.trackpac.io",
  //API_URL: "http://localhost:8000",
  mapboxAccessToken:
    "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg",
  mapStyle: "mapbox://styles/bfgneil/cle2sqoh8003s01ogafzo3xsl",

  siteTitle: "Trackpac",
  siteTitleDescription: "Empowering IoT with Trackpac",
  battery_minMV: 2700,
  battery_maxMV: 3600,
  shareUrl: "https://v2.trackpac.io/share/",
  dashboardShareUrl: "https://v2.trackpac.io/share/dashboard/",
  websiteURL: "https://v2.trackpac.io",
  Auth0Domain: "trackpac.eu.auth0.com",
  Auth0ClientId: "hSWXqIvV8hx5hNvxMq7L0wrT7TsmQueq",
  Auth0Audience: "https://v2-api.trackpac.io",
  primaryColour: "#00D09F",
  primaryColourRGB: {
    r: 0,
    g: 208,
    b: 159,
  },
  secondaryColour: "#004d40",
  defaultTheme: "light",
  defaultLanguage: "en",
  locale: "en",
  organisation_enabled: true,
  billing_enabled: true,
  manual_add: true,
  self_host: true,
  feedback_enabled: true,
  feedback_url: "https://trackpac.canny.io/feature-device-requests",
  show_geofences: false,
  api_key_enabled: true,
  register_card_enabled: true,
  defaultDateFormat: "DD/MM/YYYY",
  show_buy_link: true,
  buy_link: "https://hexaspot.com/collections/trackpac",
  support_url: "https://docs.trackpac.io/docs/introduction",
};
